@import './bootstrap';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'skote/auth';
@import 'skote/footer';
@import 'skote/horizontal_nav';
@import 'skote/layouts';
@import 'skote/page-head';
@import 'skote/right-sidebar';
@import 'skote/topbar';
@import 'skote/vertical';
@import 'icons/boxicons';
@import 'icons/materialdesignicons';

@import 'skote/components/accordion';
@import 'skote/components/avatar';
@import 'skote/components/backgrounds';
@import 'skote/components/badge';
@import 'skote/components/breadcrumb';
@import 'skote/components/buttons';
@import 'skote/components/card';
@import 'skote/components/dropdown';
@import 'skote/components/forms';
@import 'skote/components/helper';
@import 'skote/components/nav';
@import 'skote/components/pagination';
@import 'skote/components/preloader';
@import 'skote/components/print';
@import 'skote/components/progress';
@import 'skote/components/reboot';
@import 'skote/components/table';
@import 'skote/components/waves';
@import 'skote/components/widgets';

@import 'skote/components/plugins/custom-scrollbar';
@import 'skote/components/plugins/datatable';

@import '~dropzone/dist/dropzone.css';
@import '~cropperjs/dist/cropper.css';

@import 'custom';
