.alt_button_holder {
    /*
            position: absolute;
            right: 0;
            top: -50px;
    */
    position: fixed;
    right: 10px;
    top: 85px;
    z-index: 3;
    display: flex;
    gap:10px;
    align-items:flex-start;

}

.bg-white.full-height {
    background-color: rgba(var(--bs-white-rgb), 0.5) !important;
}

.main_button_holder {
    position: absolute;
    right:15px;
    top:43px;
    @media(max-width: 767px) {
        top:-4px;
    }
    &.detail {
        top: -5px;
    }
}

.fc-day-sun, .fc-day-sat {
    background-color: #f5f5f5;
}

.full-height {
    min-height: 70vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

div.dataTables_wrapper {
    div.dataTables_filter {
        text-align: left;

        label {
            display: block;
            position: relative;

            &:before {
                font-family: "boxicons";
                font-weight: 900;
                content: '\eb2e';
                position: absolute;
                left: 10px;
                top: 10px;
                height: 20px;
                width: 20px;
            }

            input {
                margin-left: 0;
                border-radius: 15px;
                padding-left: 25px;
                width: 100%;
            }
        }
    }

    thead {
        background-color: #F8F9FA;
    }

    .buttons-excel {
        margin-left: 15px;
        height: 36px;
        background-color: $button-kirmizi;
        border-color: darken($button-kirmizi, 5%);
    }

    @media screen and (max-width: 767px) {
        div.dt-buttons {
            float: none;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }
}

.dataTable.table-striped > tbody {
    tr:nth-of-type(odd) > * {
        --bs-table-accent-bg: white;
    }

    tr:nth-of-type(even) > * {
        --bs-table-accent-bg: #F8F9FA;
    }
}

.dataTable.table-hover > tbody {
    tr {
        &:hover {
            * {
                cursor: pointer;
                --bs-table-accent-bg: #EFEFEF;
            }
        }
    }
}

table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:after {
    font-family:monospace;
}
table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:after {
    font-family: monospace;
}
table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
    font-family: monospace;
}

table.dataTable {
    min-width: 600px;
    width: 100% !important;
    vertical-align: middle;
}

.render-range {
    font-size:20px;
}

.form-floating {
    .form-select, .form-control {
        height:3rem;
    }
    .form-select {
        padding-top:1.3rem !important;
        padding-bottom:0.3rem !important;
    }
    label {
        padding: 0.75rem 0.7rem;
    }
    span.selection {
        span.select2-selection {
            height:3rem;
            .select2-selection__rendered {
                position:relative;
                top:15px;
                left:3px;
                font-size:13px;
            }
        }
        .select2-selection--multiple {
            .select2-selection__rendered {

            }
            .select2-search {
                textarea {
                    position:relative;
                    top:15px;
                    left:7px;
                }

                * {
                    font-family: inherit;
                }
            }
            .select2-selection__choice {
                padding: 0 2px;
                button {
                    position:relative;
                }
            }
        }
    }
    .select_label {
        opacity:0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
}

.select2-container {
    width:100% !important;
}

.form-floating span.selection span.select2-selection {
    height: 3rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.525rem;
}

#map {
    height: 400px;
}

.productSelectField {

    .select2-selection__rendered {
        line-height: 37px !important;

    }
    .select2-container .select2-selection--single {
        height: 37px !important;
    }
    .select2-selection__arrow {
        height: 37px !important;
    }
    .select2-container--default .select2-selection--single {
        border: 1px solid #ced4da !important;
    }
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border:1px solid #ced4da !important;
}
.select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single {
    border:1px solid #ced4da !important;
}


.month_selection {
    &.active {
        background-color: transparent;
        color: #556ee6;
        border: 2px solid #556ee6;
    }
}

.badge-soft-success {
    background-color: #23c288;
    color: white !important;
}

.badge-soft-primary {
    color: white !important;
    background-color: rgb(94 121 255);
}

.list-column {
    padding:0;
    column-count: 3;
    @media(max-width:767px) {
        column-count:1;
    }
}

.find_sms_receivers, .find_email_receivers {
    cursor:pointer;
}

@media(max-width: 992px) {
    .page-content {
        padding: calc(70px + 24px) 0 60px 0;
    }
}

.file-container {
    position: relative;
    background-color: #DFDFDF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid white;
    padding: 5px;
    height: 80px;
    overflow: hidden;
    line-height: 16px;
    color: #555;
    cursor: pointer;

    div {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-color: #EFEFEF;
    }

    a {
        position: absolute;
        bottom: 0;
        right: 0;

    }
}

.btn-pluss{
    overflow: hidden;
    position: relative;
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 22px;
    width: 32px;
    top:3px;
    margin: 0 auto;
    //padding-top: 25px;
    background-color: white;
    transition: width .2s .2s ease, border-radius 1.1s ease;
    a{
        display: block;
        position: relative;
        color: #FA434B;
        text-decoration: none;
        overflow: hidden;
        padding: 5px 2px;
        border-radius: 5px;
        &:hover{
            text-decoration: inherit;
            color: white;
            background-color: #FA434B;
            transition: background-color .2s ease;
        }
    }
    &:after{
        content: '+';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        line-height: 20px;
        text-align: center;
        font-size: 1.1rem;
        background-color: #FA434B;
        color: white;
        transform: translateY(-50%) translateX(-50%);
        transition: all .3s .5s ease;
        cursor: pointer;
        cursor: hand;
    }
    ul{
        opacity: 0;
    }
    ul{
        margin-top: 5px;
        opacity: 0;
        width: 100%;
        margin-left: 0px;
        //height: 0;
        transition: all .5s ease;
        text-align: center;
        font-size: 0.9rem;
        li{

            margin-top: 5px;
            border-radius: 5px;
            width: 100%;
            height: 0px;
            overflow: hidden;
            transition: height 1s ease;

        }
    }

    //
}

.btn-pluss-wrapper:hover{
    .btn-pluss{
        width: 150px;
        //height: 120px;
        border-radius: 15px;
        padding-bottom: 5px;
        transition: width .3s ease, border-radius .3s ease, padding-bottom .3s ease;
        &::after{
            transition: all .3s ease;
            left: 50%;
            top: 10px;
            transform: translateY(-5px) translateX(-50%);
        }
        ul{
            opacity: 1;
            //height: 100%;
            margin-top: 30px;
            padding:0;
            transition: all 1s ease;
        }
        li{
            height: 30px;
            transition: height 1s ease;
            &:hover{
                border-bottom: 1px solid darken(#e9e5e5, 10);;
            }
        }
    }
}
@keyframes jump {
    0% {
        transform: translateY(3px)
    }
    50% {
        transform: translateY(-15px)
    }
    100% {
        transform: translateY(3px)
    }
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    margin-right: 40px !important;
}

.width-300 {
    width:300px;
}

.width-100 {
    width:100px;
}

.width-150 {
    width:150px;
}

.width-60 {
    width: 60px;
}

.width-70 {
    width: 70px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    padding:0 1px;
}

.image-loader-modal {
    position:fixed;
    z-index:9999;
    padding-top:100px;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow:auto;
    background-color:rgb(0,0,0);
    background-color:rgba(0,0,0,0.5);
    .modal-body {
        position:relative;
        background-color: #FEFEFE;
        margin: auto;
        padding:40px 10px;
        border:1px solid #888;
        width:80%;
        height:70%;
        box-sizing: content-box;
        .modal-content {
            width:100%;
            height:100%;
        }
    }
    .close {
        color: #aaa;
        float:right;
        font-size:28px;
        font-weight:bold;
        &:hover, &:focus {
            color: #555;
            text-decoration:none;
            cursor:pointer;
        }
    }
}
.cursor-ns-resize {
    cursor: ns-resize !important;
}
